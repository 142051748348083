<template>
    <div >
        

        <img src="/images/zhifushezhi.jpg" style="width:100%"/>

        <div  @click="$router.push({path: '/self/service/bill'})" style="width:100%;height:50px; position: absolute;top:0px;"></div>
       

    </div>

</template>

<script>
import { mapState } from 'vuex'


export default {
    data() {
        return {
            payList: [
                { id: 0, text: '更新实名', icon: '/images/pay-fen.png' },
   
            ],
            progress:0,
        }
    },
    computed: {
        ...mapState({
            fund: state => state.fund,
            userInfo: state => state.userInfo
           
        }),
        list() {
            this.fund.coin = this.returnFloat(this.fund.coin)
            this.fund.coinCash = this.returnFloat(this.fund.coinCash)
            return [
                { id: 0, text: '实名认证状态', icon: '/images/cash2.png', value: `已认证(${ this.namehua(this.userInfo.username) })` },
                { id: 1, text: '个人信息', icon: '/images/zero-down-pay.png', value: "已完善" },
                { id: 2, text: '身份证照片', icon: '/images/bank-card.png', value: '已上传' },
             
            ]
        }
    },
    mounted(){
        this.startLoading()
    },
    methods: {
        toDetail(id) {
         
            if (id==2) {
                this.$router.push({ path: '/self/service/bill/banklist'})
            }
            if (id==0) {
                this.$router.push({ path: '/self/service/chongzhi'})
            }
            if (id==1) {
                this.$router.push({ path: '/self/service/licai'})
            }
        },
        toBill() {
            this.$router.push({ path: '/self/service/bill/billList'})
        },
        namehua(name){
         if (name.length === 2) {
                return '*' + name[1];
              } else if (name.length === 3) {
                return '**' + name[2];
              }        
        },
       startLoading() {
          let interval = setInterval(() => {
            this.progress += 10;
            if (this.progress >= 80) {
              clearInterval(interval);
            }
          }, 500);
        },
        returnFloat(num) {
    num = num.toString().replace(/,/g, ""); // 转成字符串类型  如能确定类型 这步可省去
      console.log(num)
      if (num.indexOf(".") !== -1) {
        let [integerPart, decimalPart] = num.split(".");
     
        if (decimalPart.length > 2) {
          decimalPart = decimalPart.substring(0, 2);
        } else if (decimalPart.length === 1) {
          decimalPart += "0";
        }
     
        num = `${integerPart}.${decimalPart}`;
      } else {
        num += ".00";
      }
     
      return num;
    }
    }
}


</script>
<style lang="less" scoped>
.header {
    position: relative;
    z-index: 99;
    // overflow: hidden;
    height: 45px;
    display: flex;
    align-items: center;
    // font-size: 15px;
    padding: 0 15px 0 10px;
    line-height: 45px;
    background: #fff;
    opacity: 1;
    color: #000;
    user-select: none;
    -webkit-user-select: none;
    transition: all 0.3s linear;
    text-align: center;
    font-size: 17px;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Noto Sans CJK SC, WenQuanYi Micro Hei, Arial, sans-serif;
    .title {
        flex: 1;
        margin-left: 17px;
    }
    .bill {
        font-weight: 400;
        font-size: 14px;
    }
}
.item {
    background: #fff;
    width: 100%;
    padding-left: 10px;
    padding-top: 20px;
    display: flex;
    align-items: center;
    .icon {
        padding-bottom: 12px;
        img {
            width: 20px;
            height:20px;
            margin-right: 18px;
        }
    }
    .con {
        flex: 1;
        display: flex;
        align-items: center;
        padding-bottom: 18px;
        border-bottom: 1px solid #f2f2f2;
        font-size: 14px;
        .text {
            flex: 1;
            span {
                font-size: 10px;
                font-weight: 500;
                color: #F1B067;
                margin-left: 6px;
            }
        }
        .value {
            padding-right: 8px;
        }
        .van-icon {
            margin-top: 0px;
            margin-right: 10px;
        }
    }
}
.item:nth-of-type(4) {
    .icon {
        img {
            width: 20px;
            height: 20px;
            margin-left: 3px;
            margin-right: 20px;
        }
    }
}
.pay {
    background: #fff;
    width: 100%;
    padding-left: 10px;
    padding-top: 20px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    .icon {
        padding-bottom: 16px;
        img {
            width: 20px;
            height: 20px;
            margin-right: 18px;
        }
    }
    .con {
        flex: 1;
        display: flex;
        align-items: center;
        padding-bottom: 18px;
        border-bottom: 1px solid #f2f2f2;
        .text {
            flex: 1;
            span {
                font-size: 10px;
                font-weight: 500;
                color: #F1B067;
                margin-left: 6px;
            }
        }
        .value {
            padding-right: 8px;
        }
        .van-icon {
            margin-top: -2px;
            margin-right: 10px;
        }
    }
}
.bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    div {
        color: #6a7ba1;
    }
    .split {
        margin-left: 6px;
        margin-right: 6px;
        color: rgb(204, 204, 204);
    }
}.bottoma {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    div {
        color: #6a7ba1;
    }
    .split {
        margin-left: 6px;
        margin-right: 6px;
        color: rgb(204, 204, 204);
    }
}
</style>